:root {
	--header-color: linear-gradient(to right, #48b1bf, #677ebd);
	--card-header-color: linear-gradient(to right, #48b1bf, #06beb6);
	--course-card-color: linear-gradient(to left, #36d1dc, #5b86e5);
	--card-radius: 12px;
	--default-shadow: 0px 0px 12px 0px #79797973;
	--default-m-shadow: 0px 0px 6px 0px #79797973;
	--default-hover-shadow: 0px 0px 12px 0px #7979798e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 !important;
}

.main {
	background: #fbfbfb !important;
}

header {
	background: var(--header-color);
}

.app-header .nav-item .nav-link {
	color: rgba(255, 255, 255, 0.8);
	transition: 0.25s;
	font-weight: 900 !important;
}

.app-header .nav-item .nav-link:hover {
	color: rgba(255, 255, 255);
}

.card .card-header {
	/* background-color:#1E88E5; */
	background: var(--card-header-color);
	color: #fff;
	font-weight: 600;
}

.card-header small {
	font-size: 14px;
	color: #345;
	padding-left: 6px;
	font-style: italic;
}

.btn-link {
	border: none !important;
	color: #fff !important;
}

.card-header .btn-link:hover {
	color: #fff !important;
	text-decoration: none !important;
}

.card-header {
	border: none !important;
}

.card {
	appearance: none !important;
	box-shadow: 0px 0px 12px 0px #79797973;
	border-radius: var(--card-radius) !important;
	border: 0 !important;
	overflow: hidden;
}

/* home */

.listcourse {
	padding: 9px 0;
}

.course {
	background-image: var(--course-card-color);
	color: #fff;
	margin-bottom: 15px;
	padding: 16px;
	border-radius: 20px;
	font-size: 20px;
	font-weight: 700;
	cursor: pointer;
	transition: 0.5s;
}

.course:hover {
	box-shadow: 0px 0px 12px 0px #7979798e;
	background-position: 12px 0;
}

/* content */

.content {
	padding: 6px;
}

/* avatar */

.avatar-img {
	width: 80px;
	height: 80px;
	background-color: #fff;
	background-size: cover;
	border-radius: 50px;
	display: inline-block;
	line-height: 40px;
	margin: 0 10px;
	box-sizing: content-box;
	border: 2px solid #2bcddd;
}

header .avatar-img {
	width: 40px;
	height: 40px;
}

/* exercise */

.exercise {
	padding: 9px 12px;
	margin: 9px 0;
	color: #345;
	font-weight: 600;
	cursor: pointer;
	background: #efefef;
	border-radius: var(--card-radius);
	box-shadow: var(--default-m-shadow);
	transition: 0.25s;
	position: relative;
	
}

/* type here */
.exercise.done {
	color: #eee;
}

.exercise .colorPercent{
	position: absolute;
	top:0;
	left:0;
	background: var(--card-header-color);
	color: #eee;
	border-radius: inherit;
	height: 100%;
}

.exercise .title-problem{
	position: relative;
}

.exercise.done.detail {
	background: white;
	color: #0c0e10;
}

.exercise:hover {
}

.exercise .complete-level {
	position: relative;
	float: right;
}

/* pick problem */

#pickproblem .week .exercise {
	transition: 0.25s;
}

#pickproblem .week .exercise:hover {
	background: #faa;
}

#pickproblem .addweek .exercise {
	transition: 0.25s;
}

#pickproblem .addweek .exercise:hover {
	background-image: linear-gradient(#9bdf92, #9bdf92) !important;
}

#pickproblem .exercise {
	padding-left: 12px;
	vertical-align: middle;
}

#pickproblem .exercise i {
	margin-right: 6px;
}

#pickproblem .card-header input[type="radio"] {
	margin-right: 9px;
}

#pickproblem .newweek {
	text-align: center;
	margin-bottom: 9px;
	cursor: pointer;
	transition: 0.25s;
	box-shadow: 0 0 1px rgba(51, 68, 85, 0.3);
}

#pickproblem .newweek:hover {
	box-shadow: 0 0 6px #345;
}

#pickproblem #save-course {
	border-radius: var(--card-radius);
	margin-bottom: 5px;
	box-sizing: border-box;
	background: var(--header-color);
	outline: none;
}

table button {
	padding: 0px 6px !important;
}

.popup {
	border: 1px solid #aaa;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 3px;
	z-index: 999;
	background: #fff;
	min-width: 500px;
	max-width: 90%;
}

.popup .input-group.mb-3 {
	margin-bottom: 0 !important;
}

.popup input {
	width: 100%;
	padding: 6px;
	border: 1px solid #eee;
	border-radius: 3px;
}

.popup .label {
	padding: 6px 0;
	font-size: 15px;
	font-style: italic;
	display: block;
}

#alert {
	z-index: 1000;
	padding: 8px 0;
	background: rgb(241, 91, 91);
	color: #fff;
	text-align: center;
	position: fixed;
	bottom: -100px;
	right: 0;
	left: 0;
	transition: 0.5s;
}
#alert.display {
	bottom: 0;
}

@media only screen and (max-width: 991px) {
	.nav-menu {
		position: fixed;
		top: 55px;
		display: block;
		background: #fff;
		box-shadow: 1px 1px 2px #0c0e1044;
		color: #345;
		width: 100%;
	}
	.nav-menu .d-md-down-none {
		display: block !important;
	}
	.nav-menu .nav-item {
		padding: 16px;
	}
	.nav-menu .nav-item a {
		color: #345 !important;
	}
}

@media only screen and (max-width: 768px) {
	.username {
		display: none;
	}
}

@media only screen and (max-width: 399px) {
	.sideBar,
	#chat-screen .content {
		width: 359px !important;
	}
}

@media only screen and (max-width: 359px) {
	.sideBar,
	#chat-screen .content {
		width: 329px !important;
	}
}

@media only screen and (max-width: 329px) {
	.sideBar,
	#chat-screen .content {
		width: 309px !important;
	}
}

/* button : Previous - Next */
button.page-link {
	width: max-content;
	margin-left: 0;
}
/* statistic */
.dataTables_filter input[type=search]{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
	padding: .15rem;
	outline: none;
}

a:hover{
	text-decoration: none !important
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Choose"!important;
}

.modal.show .modal-dialog {
    position: relative;
    top: 20%;
}


img[src^='https://']{
	padding-left: 2em;
	max-width: 100%;
}

@media all and (max-width: 1050px){
	.main .container-fluid{
		padding: 10px !important;
	}
}

@media all and (max-width: 575px){
	img[src^='https://']{
		padding-left: 0em;
	}
}

#table_submissions th, #table_submissions td{
	 width: 1px;
	 white-space: nowrap
}

#table_submissions input{
	margin-left: -0.5em;
}

.form-control:focus{
	border-color: #e4e7ea !important;
	box-shadow: none !important;
}

.card .dropdown-menu.show{
	height: 250px;
	overflow-y: auto;
}

.card .dropdown-menu.show::-webkit-scrollbar 
{
 width: 5px;
}
.card .dropdown-menu.show::-webkit-scrollbar-track 
{
 -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
 border-radius:10px;
}
.card .dropdown-menu.show::-webkit-scrollbar-thumb
{
 background: #1b8eb7a6;
 border-radius:10px;
}