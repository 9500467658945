.sideBar{
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 400px;
    background: #dddddd73;
    z-index: 9;
}

.layer{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #80808073;
}

.close-sidebar-btn{
    position: absolute;
    z-index: 99;
    top: 5px;
    right: 0px;
    border: none;
    outline: none;
    background: transparent;
    font-size: 20px;
    color: white;
}

.close-sidebar-btn:focus{
    outline: none;
    border: none;
}